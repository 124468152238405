export enum EditPageSteps {
  Edit = 0,
  Review = 1,
  Request = 2,
}

export enum ACTION {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
  ACTIVITY_LOG = 'activity_log',
}

export enum WAF_ACTION {
  CREATE_STRATEGY = 'create_strategy',
  VIEW_STRATEGY = 'view_strategy',
  EDIT_STRATEGY = 'edit_strategy',
  STRATEGY_ACTIVITY_LOG = 'strategy_activity_log',
  GROUP_ACTIVITY_LOG = 'group_activity_log',
}

export type RemoteConstantType = {
  data_id: number;
  data: string;
};

export enum RemoteConstantEnum {
  STREAMING_METRIC = 1,
  PENALTY_ENTITY = 2,
  ALERT_CHANNEL = 3,
  EVENT_MODULE = 4,
  REGION = 6,
}

export type PageRouteParams = {
  action?: string;
  id?: string;
  type?: string;
  searchParams?: string;
  backurl?: string;
  useCopyStrategy?: string;
  isEditMode?: boolean;
};

export enum Routes {
  PORTAL_GUARD_GRAY_SCALE = '/portal-guard/gray-scale',
  PORTAL_GUARD_INVISIBLE_WATERMARK = '/portal-guard/invisible-watermark',
  PORTAL_GUARD_USER_BEHAVIOR_CONFIG = '/portal-guard/user-behavior-config',
  PORTAL_GUARD_TEXT_TEGANOGRAPHY = '/portal-guard/text-teganography',
  FETCH_UTILS_MONITOR = '/fetch-utils-monitor',
}

export enum Permission {
  PORTAL_GUARD_GRAY_SCALE_READ = 'portal-guard-gray-scale.read',
  PORTAL_GUARD_GRAY_SCALE_WRITE = 'portal-guard-gray-scale.write',
  USER_BEHAVIOR_CONFIG_READ = 'user-behavior-config.read',
  USER_BEHAVIOR_CONFIG_WRITE = 'user-behavior-config.write',
  FETCH_UTILS_MONITOR_READ = 'fetch-utils-monitor.read',
  FETCH_UTILS_MONITOR_WRITE = 'fetch-utils-monitor.write',
}

export enum KeyboardKeyCode {
  BACKSPACE = 8,
  ENTER = 13,
  ESC = 27,
  SPACE = 32,
  ARROW_LEFT = 37,
  ARROW_UP = 38,
  ARROW_RIGHT = 39,
  ARROW_DOWN = 40,
}

export enum ACSubdomain {
  ANTI_CRAWLER_ALL = 'ALL',
}

export enum SortOrder {
  DESCEND = 'descend',
  ASCEND = 'ascend',
}

export type SearchParams = {
  current: number;
  pageSize: number;
  filters?: any;
  sorter?: any;
};

export type BasicResponse = {
  is_suc: boolean;
  err_msg?: string;
};

export type FullBasicResponse = {
  is_success: boolean;
  debug_msg?: string;
};

export type OmitReqIdAndCountry<T> = Omit<T, 'request_id' | 'country'>;

export type OmitOffsetAndLimit<T> = Omit<T, 'offset' | 'limit'>;

// set fields defined in K that are part of T to be optional
export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

// opposite of optional
export type NonNullableField<T, K extends keyof T> = Omit<T, K> & {
  [P in K]: NonNullable<T[P]>;
};
