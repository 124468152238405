
// NOTICE: DO NOT MODIFY THIS FILE MANUALLY

import { createApp } from '@shopee/kaya-toast';

import siteConfig from './config';
import NotFound from './404';

import 'antd/dist/antd.css';
import '@shopee/kaya-toast/dist/index.css';

// modify ONLY this array in this file.
// Here you conld config pages and widgets with a route id and an async import.
//
// EXAMPLE:
// const bundles = [
//   {
//     routeId: '/404',
//     loader: () => Promise.resolve({ default: NotFound }),
//   },
//   {
//     routeId: '/',
//     loader: () => import('./home'),
//   },
//   {
//     routeId: 'foo',
//     loader: () => import('./foo'),
//   },
//   {
//     widgetId: 'widget-bar',
//     loader: () => import('./widget-bar'),
//   },
// ];
//

const bundles = [
  {
    routeId: '/404',
    loader: () => Promise.resolve({ default: NotFound }),
  },
  {
    routeId: '/',
    loader: () => import('./home'),
  },
  {
    routeId: '/fetch-utils-monitor',
    loader: () => import('./fetch-utils-monitor'),
  },
  {
    routeId: '/portal-guard/gray-scale',
    loader: () => import('./portal-guard--gray-scale'),
  },
  {
    routeId: '/portal-guard/invisible-watermark',
    loader: () => import('./portal-guard--invisible-watermark'),
  },
  {
    routeId: '/portal-guard/text-teganography',
    loader: () => import('./portal-guard--text-teganography'),
  },
  {
    routeId: '/portal-guard/user-behavior-config',
    loader: () => import('./portal-guard--user-behavior-config'),
  },
  {
    widgetId: '/widget-sife-sidenav',
    loader: () => import('./widget-sife-sidenav'),
  },
];

createApp(bundles, siteConfig);
