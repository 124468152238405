// v1 - https://git.garena.com/shopee/isfe/toastack/kaya-toast/-/issues/13
// v2 - https://git.garena.com/shopee/isfe/toastack/kaya-toast/-/issues/124

import { postJSON } from 'src/_api';
import { AuthResponse, SoupUser } from './typing';

// ---- User info utils ----

type UserInfo = {
  username: string;
  userid: string;
  email: string;
  picture?: string;
  token?: string;
};

type UserLoginInfo = {
  user: UserInfo;
  permissions?: string[];
  extra?: any;
};

// if { username, userid, email, ...rest }, change it to { user: { username, userid, email }, ...rest }
// if user is already in place, it will take precedence over the spreaded fields in the return spread
const shiftUserFieldsToUser = (info: SoupUser): UserLoginInfo => {
  const { username, userid, email, pci_url, ...rest } = info;
  return {
    user: { username, userid: userid.toString(), email, picture: pci_url },
    ...rest,
  };
};

const parseLoginResult = (info: SoupUser): UserLoginInfo => {
  return shiftUserFieldsToUser(info);
};

// ---- Generate fallback functions ----

type AuthAPIs = {
  isLoggedInFallback: () => Promise<boolean>;
  getLoginInfoFallback: () => Promise<UserLoginInfo | null>;
  goLoginFallback: (returnUrl?: string) => Promise<void>;
  goLogoutFallback: () => Promise<void>;
};

type Endpoints = {
  isLoggedInEndpoint?: string;
  getLoginInfoEndpoint?: string;
  logoutEndpoint?: string;
};

const DEFAULT_ENDPOINTS = {
  isLoggedInEndpoint: `http://localhost:8085/fetch_utils_monitor/anti_fraud.tss_admin.fetch_utils_monitor.auth`,
  getLoginInfoEndpoint: `http://localhost:8085/fetch_utils_monitor/anti_fraud.tss_admin.fetch_utils_monitor.auth`,
  logoutEndpoint: `http://localhost:8085/fetch_utils_monitor/anti_fraud.tss_admin.fetch_utils_monitor.auth`,
};

const generateAPIs = (endpoints?: Endpoints): AuthAPIs => {
  let lastFetchedUserInfo: UserLoginInfo | null;
  let lastRedirectUrl: string;

  let isLoggedInPromise: boolean | undefined;
  let goLoginPromise: Promise<void> | undefined;
  let goLogoutPromise: Promise<Response> | undefined;

  const isLoggedIn = async (): Promise<boolean> => {
    if (!isLoggedInPromise) {
      const url =
        endpoints?.getLoginInfoEndpoint ||
        DEFAULT_ENDPOINTS.getLoginInfoEndpoint;
      const res: AuthResponse = await postJSON(url, {});
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!res?.redirect_url) {
        lastRedirectUrl = res.redirect_url;
        return false;
      }
      if (res.user) {
        isLoggedInPromise = true;
        lastFetchedUserInfo = parseLoginResult(res.user);
        return true;
      }
    }
    return !!isLoggedInPromise;
  };

  const getLoginInfo = async (): Promise<UserLoginInfo | null> => {
    await isLoggedIn();
    return lastFetchedUserInfo;
  };

  const goLogin = async (): Promise<void> => {
    if (!goLoginPromise) {
      if (lastRedirectUrl) {
        goLoginPromise = new Promise(() => {
          location.href = lastRedirectUrl;
        });
      } else {
        console.error(
          'There is no valid login config. Please take a double check!'
        );
        goLoginPromise = new Promise(() => {
          // wait forever
        });
      }
    }
    return await goLoginPromise;
  };

  const goLogout = async (): Promise<void> => {
    if (!goLogoutPromise) {
      const url = endpoints?.logoutEndpoint || DEFAULT_ENDPOINTS.logoutEndpoint;
      goLogoutPromise = postJSON(url, {});
    }
    await goLogoutPromise;
    location.reload();
  };

  return {
    getLoginInfoFallback: getLoginInfo,
    isLoggedInFallback: isLoggedIn,
    goLoginFallback: goLogin,
    goLogoutFallback: goLogout,
  };
};

export default generateAPIs;
