import { SiteConfig } from '@shopee/kaya-toast';
import generate from './_shared/auth';
import { Permission, Routes } from '_shared/typings/common';

const siteConfig: SiteConfig = {
  siteName: 'Security Infra FE Common Library',
  strictMode: false,
  sideNavWidgetId: 'widget-sife-sidenav',
  /** You can have custom top and side navs through widgets */
  // topNavWidgetId: 'widget-customized-topnav',
  // sideNavWidgetId: 'widget-customized-sidenav',

  /** You can define an app level wrapper for things alike Redux provider, global listeners, etc */
  // wrapperId: 'widget-app-wrapper'
  topNav: [
    {
      type: 'profile',
      items: [],
    },
  ],
  sideNav: [
    {
      title: 'Fetch Utils',
      iconType: 'barChart',
      type: 'folder',
      items: [
        {
          title: 'Fetch Utils Monitor',
          type: 'link',
          routeId: Routes.FETCH_UTILS_MONITOR,
          // @ts-expect-error permission control in widget sife sidenav
          permissions: [Permission.FETCH_UTILS_MONITOR_READ],
        },
      ],
    },
    {
      title: 'Portal Guard',
      iconType: 'FileProtect',
      type: 'folder',
      items: [
        {
          title: 'User Behavior Config',
          type: 'link',
          routeId: Routes.PORTAL_GUARD_USER_BEHAVIOR_CONFIG,
          // @ts-expect-error permission control in widget sife sidenav
          permissions: [Permission.USER_BEHAVIOR_CONFIG_READ],
        },
        {
          title: 'Portal Guard Gray Scale',
          type: 'link',
          routeId: Routes.PORTAL_GUARD_GRAY_SCALE,
          // @ts-expect-error permission control in widget sife sidenav
          permissions: [Permission.PORTAL_GUARD_GRAY_SCALE_READ],
        },
        {
          title: 'Invisible Watermark Process',
          type: 'link',
          routeId: Routes.PORTAL_GUARD_INVISIBLE_WATERMARK,
          // @ts-expect-error permission control in widget sife sidenav
          permissions: [Permission.USER_BEHAVIOR_CONFIG_READ],
        },
        {
          title: 'Decode Text Teganography',
          type: 'link',
          routeId: Routes.PORTAL_GUARD_TEXT_TEGANOGRAPHY,
          // @ts-expect-error permission control in widget sife sidenav
          permissions: [Permission.USER_BEHAVIOR_CONFIG_READ],
        },
      ],
    },
  ],
  authentication: {
    /**
    If your auth APIs need customization, you can use the `generate` function in `_shared/auth`
    to replace the default `getAPIsFromEndpoints` function below. The `generate` function is a
    copy of `getAPIsFromEndpoints` for your easy customization.
    You might also need to change the mock behavior of these auth APIs in `devServer/index.ts`
     */
    fallbackFunctions: generate({
      getLoginInfoEndpoint: '/api/auth',
      isLoggedInEndpoint: '/api/auth',
      logoutEndpoint: '/api/logout',
    }),
  },
};

export default siteConfig;
