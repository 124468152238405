import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';

const returnJson = <T,>(response: AxiosResponse<T>) => response.data;

export const request = <T,>(config: AxiosRequestConfig) => {
  const promise = axios.request<T>(config);
  return promise;
};

export const sendJson = <T,>(
  url: string,
  payload: any,
  method: 'POST' | 'PUT' | 'PATCH',
  cancelToken?: any,
  headers?: any
): Promise<T> => {
  return request<T>({
    url,
    headers,
    data: payload,
    method,
    validateStatus: () => true, //temp: prevent axios from throwing error
    cancelToken: cancelToken && cancelToken.token,
  }).then(returnJson);
};

export const postJSON = <T,>(
  url: string,
  payload: any,
  cancelToken?: any,
  headers?: any
) => sendJson<T>(url, payload, 'POST', cancelToken, headers);
