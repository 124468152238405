import * as React from 'react';
import { Link } from '@shopee/kaya-toast';
import { FluidPage, FluidContent } from '../_shared/Content';

export const layout = 'fluid';

const Page404: React.ComponentType<{ error?: string }> = ({
  error,
}: {
  error?: string;
}): JSX.Element => {
  if (error) {
    console.error(error);
  }
  return (
    <FluidPage>
      <FluidContent>
        <h1>Not found</h1>
        <p>
          <Link routeId="/">back to home</Link>
        </p>
      </FluidContent>
    </FluidPage>
  );
};

export default Page404;
